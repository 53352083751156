* {
  font-family: $font_family;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font_family;
  position: relative;
}

h1,
h2,
h3 {
  font-family: $font_family;
}

p {
  font-family: $font_family;
}