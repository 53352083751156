.primary-btn {

  background-color: rgba(0, 0, 0, 0, );
  border: 1px solid $white;
  height: 60px;
  width: 155px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  .div_wrapper {
    display: flex;
    align-items: center;
  }

  .vertical_div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .yellow {
    background-color: $yellow;
    width: 7px;
    height: 40px;
  }

  .pink {
    background-color: $pink;
    width: 7px;
    height: 20px;
  }

  .blue {
    background-color: $blue;
    width: 7px;
    height: 60px;
  }

  .hover-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
  }

  .label {
    color: $white;
    margin: auto;
    font-size: 22px;
    font-weight: $weight_semiBold;
  }

  &:hover {
    border: 1px solid transparent;
    overflow: visible;

    .yellow,
    .pink,
    .blue {
      display: none;
    }

    .hover-button {
      opacity: 1;
      width: 155px;
      height: 61px;
    }
  }

  &:active {
    .hover-button {
      background-color: #fff;
    }

    .label {
      color: $black;
      z-index: 10;
    }
  }
}

.submit-label {
  color: $white;
  margin: auto;
  font-size: 22px;
  font-weight: $weight_semiBold;
  background-color: transparent;
  border-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 3px;
  z-index: 5;

  &:active {
    color: $black;
  }
}

.secondary-btn {
  background-color: $white;
  height: 60px;
  width: 155px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  .div_wrapper {
    display: flex;
    align-items: center;
  }

  .vertical_div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .yellow {
    background-color: $yellow;
    width: 7px;
    height: 40px;
  }

  .pink {
    background-color: $pink;
    width: 7px;
    height: 20px;
  }

  .blue {
    background-color: $blue;
    width: 7px;
    height: 60px;
  }

  .hover-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
  }

  .label {
    color: $black;
    margin: auto;
    font-size: 22px;
    font-weight: $weight_semiBold;
  }

  &:hover {
    border: 0px solid transparent;
    overflow: visible;

    .yellow,
    .pink,
    .blue {
      display: none;
    }

    .hover-button {
      opacity: 1;
      width: 155px;
      height: 61px;
    }
  }


  &:active {
    background-color: #ffffff00;

    .label {
      color: $white;
      z-index: 10;
    }
  }

}

@media screen and (max-width: 500px) {
  .primary-btn:hover {
    border: 1px solid $white;
    overflow: hidden;
  }

  .primary-btn:hover,
  .secondary-btn:hover {

    .yellow,
    .pink,
    .blue {
      display: block;
    }

    .hover-button {
      opacity: 0;
    }
  }

  .primary-btn:active {
    background-color: $white;
  }
  .secondary-btn:active {
    border:1px solid $white;
  }

  // adjust size of buttons on mobile view
  .primary-btn,.secondary-btn{
    height: 45px;
    width: 116px;
    .label,.submit-label{
        font-size: 16px;
    }
  }

}