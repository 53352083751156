.home {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gif-section {
    width: 100%;
    position: relative;
    // background-image: url('../../assets/gif-file/mainPageforWebsite1440.gif');
    // background-repeat: no-repeat;
    // background-size: 100%;
    // height: 1200px;
    height: 100vh;
  }

  .gif-section .hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 5;
    object-fit: cover;
  }

  .hero-hide {
    display: none;
  }

  .video {
    width: 100%;
    height: 100%;
    max-height: 90vh;
    object-fit: cover;
    display: block;
  }

  .video-hide {
    display: none;
  }

  .play-button {
    position: absolute;
    top: 25%;
    left: 47%;
    width: 10%;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    z-index: 10;
  }

  .play-hover {
    position: absolute;
    top: 25%;
    left: 47%;
    width: 10%;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    z-index: 10;
  }

  .play-container:hover .play-button {
    opacity: 0;
  }

  .play-container:hover .play-hover {
    opacity: 1;
  }


  .play-container {
    display: block;
  }

  .play-hide {
    display: none;
  }

  .close-container {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .close {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .close-hover {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    transition: opacity .3s ease-in;
  }

  .close-container:hover .close-hover {
    opacity: 1;
  }

  .close-hide {
    display: none;
  }


  .team-section {
    //margin: 0 10%;
    //max-width: 1100px;
    max-width: 1100px;
    width:60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .team-section .text {
    margin-bottom: 2rem;
  }

  .team-section .primary-btn {
    margin-top: 1rem;
  }

  .carousel-section {
    max-width: 1100px;
    margin: 0 2rem;
    margin-top: 134px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 134px;
  }

  .image-wrapper {
    height: 35vw;
    margin: 0 auto;
    overflow: hidden;
  }

  .image,
  .shadow {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  .title-wrapper {
    position: absolute;
    bottom: -2px;
    left: 20px;
    max-width: 55%;
  }

  .project-title {
    color: $white;
    font-size: 48px;
    font-weight: $weight_extraBold;
    margin-bottom: 1rem;
    text-align: left;
  }

  .image-wrapper .project-description {
    color: $white;
    font-size: 16px;
    font-weight: $weight_medium;
    text-align: left;
  }

  .image-wrapper img {
    object-fit: cover;
  }

  .carousel-title {
    font-weight: $weight_bold;
    font-size: 38*2px;
    text-align: center;
    #text-transform: uppercase;
    color: $blue;
  }

  .carousel-container {
    position: relative;
    width: 85vw;
    max-width: 65vw;
  }

  .carousel-container .carousel-slider {
    overflow: visible;
  }

  .carousel-container .control-dots {
    bottom: -35px;
  }

  .carousel-container>div>div>ul>li.dot {
    width: 12px;
    height: 12px;
    background-color: #ffffff00;
    border: 2px solid $white;
  }

  .carousel-container>div>div>ul>li.dot:hover {
    background-color: $yellow;
  }

  .carousel-container>div>div>ul>li.dot.selected {
    background-color: $yellow;
    border: 2px solid $yellow;
  }

  .carousel-container>div>div>ul>li.dot:hover {
    background-color: $yellow;
    border: 2px solid $yellow;
  }


  .carousel-section .primary-btn {
    position: absolute;
    bottom: -100px;
    left: 20px;
  }


  .contactus-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96%;
    gap: 3rem;
    max-width: 1200px;
    padding: 134px 0;
  }

  .contactus-section h2 {
    font-weight: $weight_bold;
    font-size: 38*2px;
    text-align: right;
    //text-transform: uppercase;
    color: $pink;
    max-width:100vw;
  }

  .form {
    width: 65%;
  }

  .name-email {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex: 1 0 50%;
  }

  .name-email .input-wrapper {
    flex: 1 0 auto;
  }

  .input-wrapper {
    margin-top: 2rem;
  }

  .buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .title {
    font-weight: $weight_bold;
    font-size: 38*2px;
    color: $yellow;
    #text-transform: uppercase;
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .text {
    color: $white;
    font-size: 22px;
    //font-weight: $weight_semiBold;
    line-height: 38.5px;
    text-align:center;
  }
}

@media screen and (min-width: 1700px) {
  .home {

    .team-section{
      width: 55vw;
    }

    .play-button,
    .play-hover {
      left: 47%;
    }

    .title-wrapper {
      max-width: 40%;
    }
  }
}

@media screen and (min-width: 1200px){

    .home {
        .contactus-section h2 {
          max-width:30%;
        }
    }

}


@media screen and (max-width: 1200px) {
  .home {

    // .gif-section {
    //   background-image: url('../../assets/gif-file/mainPageforWebsite1200.gif');
    //   height: 900px;
    // }

    .team-section{
      width: 80vw;
    }

    .image-wrapper .project-description {
      max-width: 80%;
    }

    .contactus-section h2 {
      //font-size: 80px;
      width:33vw;
      //width:30%;
    }

    .play-button,
    .play-hover {
      width: 18%;
      left: 44%;
    }

    .name-email .input-wrapper {
      width: 40%;
    }
  }
}

@media screen and (max-width: 912px) {
  .home {
    // .gif-section {
    //   background-image: url('../../assets/gif-file/mainPageforWebsite500.gif');
    //   height: 800px;
    // }

    .play-button {
      width: 150px;
      top: 28%;
      left: 42%;
    }

    .play-hover {
      width: 150px;
      top: 28%;
      left: 42%;
    }

    .carousel-container {
     max-width: 100vw;
    }
    .image-wrapper {
      height: 45vw;
    }

    .image-wrapper .project-description {
      max-width: 100%;
      font-size: 0;
    }

    .image-wrapper .project-title {
      bottom: 35px;
      font-size: 30px;
    }

    .contactus-section {
      flex-direction: column;
      gap: 1rem;
    }

    .contactus-section h2 {
      margin-bottom: 0;
      //font-size: 45px;
      text-align:center;
      width:100vw;

    }


    .name-email {
      flex-direction: column;
      gap: 0;
    }

    .name-email .input-wrapper {
      width: 100%;
    }

    .buttons {
      flex-direction: column;
      gap: 2rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .home {
    .play-button {
      left: 30%;
    }

    .play-hover {
      left: 30%;
    }

    .gif-section {
      background-image: url('../../assets/gif-file/mainPageforWebsite500.gif');
      background-repeat: no-repeat;
      background-size: 100%;
      height: 400px;
    }

    .gif-section .hero {
      display: none;
    }

    .play-container:hover .play-button {
      opacity: 1;
    }
  
    .play-container:hover .play-hover {
      opacity: 0;
    }

    .title {
      width: 90vw;
      font-size: 50px;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }

    .text {
      font-size: 16px;
      line-height:150%;
    }

    .carousel-title{
      font-size: 50px;
    }

    .project-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .team-section {
      margin-top: 0;
    }

    .carousel-section {
      margin-top: 70px;
      margin-bottom: 70px;
    }

    .carousel-section .project-title {
      font-size: 16px;
      //margin-bottom: 0.5rem;
    }

    .project-description {
      font-size: 14px;
    }

    .image-wrapper {
      height: 300px !important;
    }

    .contactus-section {
      padding: 70px 0;
    }
    .contactus-section h2{
      font-size: 50px;
    }
  }
}