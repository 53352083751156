.footer {
  background-color: #DCDCDC;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 276px;
  // width: 100%;

  ul {
    display: flex;
    align-items: center;
    margin: 3.7rem 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0 1rem;
  }

  .contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
  }

  .email {
    font-size: 18px;
    font-weight: $weight_regular;
    margin: 0;
    margin-top: .5rem;
    text-decoration: none;
    color: $black;
  }

  .contact {
    font-style: 12px;
    font-weight: $weight_regular;
  }

  .copyright {
    margin: 0;
  }
}

@media  screen and (max-width: 600px) {
  .footer {
    ul {
      margin: 3rem 0 !important;
    }
  }
}