.reel-card {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 1rem 0 1rem;

  /////////////////////////////////////////////////////
  // Here you can change the size of images in Reels page
  //////////////////////////////////////////////////////
  .image-container {
    position: relative;
    width: 438px;
    height: 570px;
    border-radius: 4px;
    overflow: hidden;
  }

  .image {
    width: 438px;
  }

  .image,
  .shadow {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .play-button {
    position: absolute;
    top: 37%;
    left: 37%;
    opacity: 1;
    transition: opacity .5s ease-in-out;
  }

  .play-hover {
    position: absolute;
    top: 37%;
    left: 35.5%;
    opacity: 0;
    transition: opacity .5s ease-in-out;
  }

  .play-hover:hover {
    opacity: 1;
  }

  .play-hover:hover .play-button {
    opacity: 0;
  }

  .project-title {
    position: absolute;
    bottom: 2px;
    left: 10px;
    z-index: 10;
    color: $white;
    font-size: 36px;
    font-weight: $weight_bold;
  }
}

@media screen and (max-width: 550px){
  .play-button {
    left: 33% !important;
  }

  .play-hover{
    left: 31.5% !important;
  }

  .play-hover:hover {
    opacity: 0 !important;
  }
}