.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 100;
  padding-top: 4rem;

  background: rgba(34, 34, 34, 0.87);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.responsive-iframe {
  width: 70vw;
  height: calc(70vw * 9 / 16); /* 16:9 aspect ratio */
  box-shadow: 0px 4px 28px 12px rgba(49, 49, 49, 0.25);
  border-radius: 10px;
}

.video-grid {
  position: relative;
}

.cancel {
  position: absolute;
  top: -35px;
  right: 0px;
  transition: all 0.3s ease;
}

.cancel:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 800px) {
  .responsive-iframe {
    width: 90vw;
    height: calc(90vw * 9 / 16); /* 16:9 aspect ratio */
    box-shadow: 0px 4px 28px 12px rgba(49, 49, 49, 0.25);
    border-radius: 10px;
  }
}
