.navigation {
  position: relative;

  .blue-border,
  .pink-border,
  .yellow-border {
    background-color: transparent;
    border: 3px solid $black;
    border-radius: 3px;
  }

  .blue-border {
    margin: 0 0 -6px -6px;
  }

  .pink-border {
    margin: 0 0 -6px -6px;
  }

  .text {
    padding: 5px 10px 10px 15px;
    text-align: center;
    margin: 0 1rem;
    font-weight: $weight_bold;
  }

  .yellow-active {
    color: $yellow;
    padding: 5px 10px 10px 15px;
    text-align: center;
    margin: 0 1rem;
    font-weight: $weight_bold;
  }

  .blue-active {
    color: $blue;
    padding: 5px 10px 10px 15px;
    text-align: center;
    margin: 0 1rem;
    font-weight: $weight_bold;
  }

  &:active {
    background-color: #fff;

    .text {
      color: black;
    }
  }

  &:hover {

    .blue-border {
      border: 3px solid $blue;
    }

    .pink-border {
      border: 3px solid $pink;
    }

    .yellow-border {
      border: 3px solid $yellow;
    }
  }
}

@media screen and (max-width: 800px) {
  .navigation {
    &:hover {

      .blue-border,
      .pink-border,
      .yellow-border {
        border: 3px solid $black;
      }
    }
  }
}