.work-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 1rem 0 1rem;

  .image-container {
    position: relative;
    width: 438px;
    height: 570px;
    border-radius: 4px;
    overflow: hidden;
  }

  .image,
  .shadow {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .shadow {
    opacity: 0;
  }

  .project-title {
    position: absolute;
    bottom: 5px;
    left: 20px;
    z-index: 10;
    color: $white;
    font-size: 36px;
    font-weight: $weight_bold;
    opacity: 0;
  }

  .project-description {
    position: absolute;
    bottom: 8px;
    left: 20px;
    z-index: 10;
    color: $white;
    font-size: 24px;
    font-weight: $weight_medium;
    opacity: 0;
  }

  .image-container:hover .shadow,
  .image-container:hover .project-title,
  .image-container:hover .project-description {
    opacity: 1;
  }
}

@media screen and (max-width: 450px) {
  .work-card{
    .image-container {
      height: 320px;
      width: 245px;
    }

    .image, .shadow {
      width: 245px;
    }

    .project-title {
      bottom: 2px !important;
      font-weight: $weight_semiBold;
      font-size: 32px;
    }
  }
}

