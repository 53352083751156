.header {
  background: linear-gradient(180deg, $black 80%, rgba(0, 0, 0, 0) 100%);
  height: 120px;
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 20;
  margin-bottom: -1.5rem;


  a {
    color: $white;
    text-decoration: none;
    margin: 0 0.7rem;
    font-size: 20px;
    font-weight: $weight_semiBold;
  }

  .navigation-list {
    display: flex;
    justify-self: end;
  }

  img {
    margin: 10px 0 10px 25px;
    width: 130px;
  }

  .hamburgermenu {
    display: none;
  }

  .yellow-line {
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    background-color: $yellow;
  }

  .blue-line {
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    background-color: $blue;
  }

  .pink-line {
    width: 28px;
    height: 3px;
    background-color: $pink;
  }
}

@media screen and (max-width: 1000px) {
  .header {
    a {
      margin: 0 .3rem;
    }
  }
}

@media screen and (max-width: 950px) {
  .header {

    .hamburgermenu {
      display: block;
      justify-self: end;
      margin-right: 1rem;
      position: relative;
      height: 50px;
      width: 50px;
    }

    .yellow-open {
      display: block;
      animation: rotation-yellow .3s ease-in forwards;
      width: 28px;
      height: 3px;
      background-color: $yellow;
      position: absolute;
      margin-top: .5rem;
      margin-right: 1rem;
      top: 0;
      right: 0;

    }

    .blue-open {
      display: block;
      animation: rotation-blue .3s ease-in forwards;
      width: 28px;
      height: 3px;
      background-color: $blue;
      position: absolute;
      margin-top: .5rem;
      margin-right: 1rem;
      top: 0;
      right: 0;
    }

    @keyframes rotation-yellow {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(45deg);
      }
    }

    @keyframes rotation-blue {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(-45deg);
      }
    }

    .navigation-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      top: 100px;
      right: 0;
      z-index: 10;
      background-color: $black;
      width: 100vw;
      height: 100vh;
      padding-top: 3rem;
      transform: translateX(0);
      transition: all .6s ease-in;
      animation: fadeIn .5s;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .navigation-mobile a {
      margin: 1.5rem 0;
    }


    .navigation-list {
      display: none;
    }
  }
}