.work {
  background-color: $black;
  min-height: 100vh;
  padding: 134px 38px;

  .work-page-center {
    //max-width: 1600px;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin: 0;
  }

  .title {
    font-weight: $weight_bold;
    font-size: 38*2px;
    text-align: center;
    color: $yellow;
    //text-transform: uppercase;
    margin-bottom: 3rem;
  }

  .text {
    color: $white;
    font-size: 22px;
    //font-weight: $weight_semiBold;
    line-height: 38.5px;
  }

  .description-section {
    max-width: 1100px;
    //max-width:50vw;
    margin: auto;
    //margin-bottom: 134px;
    margin-bottom: 6vw;
    text-align:center;
  }

  .blue-title {
    color: $blue;
  }

  .gallery-title div:hover {
    color: $pink;
  }


  .image-container:hover:after{
    background: $white;
    opacity: 0.10;
    content: '';
    position: absolute;
    width: 438px;
    height: 570px;
    }

  .gallery {
    display: flex;
    max-width: 100%;
    margin: 0 auto;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    //max-width: 1600px;
    max-width:73vw;

  }

  .gallery-title {
    font-weight: $weight_semiBold;
    font-size: 22px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: -40px;
  }

  .gallery-title div {
    margin: 0 1rem;
    cursor: pointer;
  }

}


@media screen and (min-width: 1970px ){

    .work {

        .work-page-center{
            max-width:50vw;
        }
        .gallery{
            max-width:50vw;
        }
    }


}

@media screen and (max-width: 1310px ){

    .work {

        .gallery{
            max-width:80vw;

        }

    }

}

@media screen and (max-width: 1194px ){

    .work .description-section {
        max-width:60vw;

    }

}
@media screen and (max-width: 600px){
  .work {
      padding: 66px 38px;

      .work-page-center {
        max-width:80vw;

      }

      .description-section{
        max-width:80vw;
      }

      .title {
        font-size: 50px;
      }

      .text {
        font-size: 16px;
        line-height: 150%;
      }

      .gallery-title div:hover {
        color: $blue !important;
      }

      .image-container {
        max-width: 300px;
      }
  }
}

@media screen and (max-width: 400px) {
  .work {
    padding: 70px 38px;
  }

  .description-section {
    margin-bottom: 70px;
  }
}