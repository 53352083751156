.input {
  .div_wrapper {
    display: flex;
    align-items: center;
  }

  .vertical_div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .yellow {
    background-color: $yellow;
    width: 7px;
    height: 40px;
  }

  .pink {
    background-color: $pink;
    width: 7px;
    height: 20px;
  }

  .blue {
    background-color: $blue;
    width: 7px;
    height: 60px;
  }

  .label {
    color: $white;
    margin: auto;
    font-size: 18px;
    font-weight: $weight_medium;
  }

  .input-type {
    display: flex;
    border: 1px solid $white;
    border-radius: 2px;
    overflow: hidden;
    margin-top: .5rem;
  }

  .input-type input {
    background-color: rgba(0,0,0,0);
    height: 60px;
    border: none;
    width: 100%;
    padding-left: 5px;
    color: $white;
    font-size: 18px;
    font-weight: $weight_regular;
  }

  .input-type input:focus {
    background-color: $white;
    font-size: 18px;
    font-weight: $weight_regular;
    color: black;
  }

}