.team-page {
  background-color: $black;
  min-height: 100vh;
  padding: 134px 38px;

  .team-page-center {
    //max-width: 1600px;
    //max-width: 60vw; not needed when team is just three members
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin: 0;
  }

  .description-section {
    max-width: 1100px;
    margin: auto;
    margin-bottom: 3vw;
  }

  .title {
    font-weight: $weight_bold;
    font-size: 38*2px;
    text-align: center;
    color: $yellow;
    //text-transform: uppercase;
    margin-bottom: 3rem;
    text-align: center;
  }

  .text {
    color: $white;
    font-size: 22px;
    //font-weight: $weight_semiBold;
    line-height: 38.5px;
    text-align:center;
  }

  .separator_dots{
    margin-top:3vw;
  }

  .services {
    display: flex;
    margin-top: 50px;
    gap: 1rem;
    justify-content: center;
    padding: 0;
  }


  .services li {
    list-style: none;
    margin: 0 .5rem;
  }

  .service-title {
    font-size: 22px;
    font-weight: $weight_bold;
    color: $blue;
    text-align: center;
    text-transform: uppercase;
  }

  .service-description {
    font-size: 16px;
    font-weight: $weight_medium;
    color: $white;
    text-align: center;
    line-height: 1.5rem;
  }

  .description-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease;
  }

  .description-container.visible {
    max-height: 300px;
    /* Adjust the height as needed */
    transition: max-height 0.5s ease;
  }

  .description {
    color: white;
    font-size: 22px;
    margin-left: 1.3rem;
    margin-top: 0.4rem;
  }

  .team-list-title {
    font-weight: $weight_bold;
    color: $yellow;
    font-size: 38*2px;
    text-align: center;
    //padding-bottom: 4rem;
    margin-top:3rem;
    margin-bottom: 5rem;
  }

  .team-list-details {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 68px;
    padding-bottom: 11rem;
  }

  .team-card {
    width: 350px;
    position: relative;

    .image-container {
      position: relative;
      overflow: hidden;
      margin: auto;
    }

    img {
      border-radius: 4px;
      width: 330px;
      height: 473px;
      object-fit: cover;
    }

    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 340px;
      height: 100%;
      background-image: linear-gradient(180deg,
          rgba(170, 181, 195, 0) 0%,
          #121212 100%);
    }

    .team-card-text {
      transition: all 2s ease;
      color: white;

      .float-text {
        position: absolute;
        top: 380px;
        left: 10px;
      }

      .name {
        font-weight: $weight_bold;
        font-size: 36px;
        margin: 0;
        padding-bottom: 10px;
        margin-left: 10px;
      }

      .job-title {
        font-size: 18px;
        margin: 0;
        margin-left: 10px;
        //font-weight: $weight_semiBold;
      }

      .more-details {
        font-weight: $weight_medium;
        font-size: 22px;
        margin: 0;
        padding-top: 13px;
        margin-left: 1.3rem;

        .arrow {
          transition: 0.5s;
          width: 40px;
        }

        .down {
          transform: rotateX(180deg);
          width: 40px;
        }
      }
    }
  }
}

/* this secction was only used display images two X two
@media screen and (min-width: 1970px ){

    .team-page .team-page-center {
        max-width:50vw;

    }

}

@media screen and (max-width: 1280px ){

    .team-page .team-page-center {
        max-width:80vw;

    }

}
*/

@media screen and (max-width: 960px) {
  .services {
    flex-direction: column;
  }

  .team-page .team-page-center {
    max-width:90vw;

    }
}

@media screen and (max-width: 600px) {
  .team-page {
    padding: 66px 38px;

    .team-page-center .team-title {
      margin: 0 1rem 0 1rem;
    }

    .team-page-center {
      max-width:80vw;

    }
    .team-card .image-container {

      overflow:unset;

    }


    .title {
      font-size: 50px !important;
    }

    .team-list-title{
      font-size: 50px !important;
    }

    .text {
      font-size: 16px !important;
      line-height: 150%;

    }

    .service-description {
      font-size: 16px;
    }

    .more-details {
      font-size: 18px !important;
      display: flex;
      align-items: center;
      gap: .5rem;
    }

    .description {
      font-size: 17px !important;
      font-weight: $weight_medium;
    }

    .arrow {
      height: 20px !important;
      width: 16px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .team-page {
    padding: 70px 38px;
  }

  .team-card .image-container {

      overflow:hidden;

    }

  .description-section {
    margin-bottom: 70px;
  }
}
